<template>
  <div class="verification-container">
    <div class="input-container loginCode">
      <input 
        v-model="userInput"
        :placeholder="inputPlaceholder"
        @input="checkCode"
        maxlength="4"
        class="loginInput"
      />
    </div>
    <div class="verification-code">
      <div class="code-display" @click="generateCode">{{ verificationCode }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      verificationCode: '',
      userInput: '',
      confirmSuccess: false,
      inputPlaceholder: this.$store.state.language == "en" 
        ? "Enter verification code" 
        : "请输入验证码"
    };
  },
  methods: {
    // 生成随机验证码
    generateCode() {
      const characters = '0123456789';
      let code = '';
      for (let i = 0; i < 4; i++) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.verificationCode = code;
    },
    // 检查验证码
    checkCode() {
      if (this.userInput.length === 4) {
        if (this.userInput === this.verificationCode) {
          this.confirmSuccess = true;
          localStorage.setItem("isAdoptVerification", true);
          this.$message.success(
            this.$store.state.language == "en" 
              ? "Verification passed" 
              : "验证通过"
          );
        } else {
          this.$message.error(
            this.$store.state.language == "en" 
              ? "Incorrect code" 
              : "验证码错误"
          );
          this.userInput = '';
          this.generateCode();
          this.confirmSuccess = false;
          localStorage.setItem("isAdoptVerification", false);
        }
      } else {
        this.confirmSuccess = false;
        localStorage.setItem("isAdoptVerification", false);
      }
    }
  },
  mounted() {
    this.generateCode();
  }
};
</script>

<style scoped>
.verification-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .verification-container {
    margin-top: 20px;
  }
}

.verification-code {
  display: flex;
  align-items: center;
}

.code-display {
  background-color: #f5f6fb;
  padding: 10px 20px;
  font-size: 24px;
  letter-spacing: 5px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

.input-container {
  text-align: center;
  flex: 1;
}

/* .input-container input {
  width: 200px;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 5px;
} */

.input-container input:focus {
  outline: none;
  border-color: #409EFF;
}
.loginInput:focus {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
  border-color: #2849aa;
}
.loginInput {
  outline: none;
  display: block;
  width: 100%;
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  border: none;
  /* border-bottom: 1px solid #f4f4f4; */
}
.loginInput {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.loginCode {
background-image: url("../../assets/images/login/code.png");
background-size: 24px;
background-position: left center;
background-repeat: no-repeat;
padding-left: 44px;
}
</style>