import Main from "../views/BasePage/Main.vue";
import Login from "../views/BasePage/Login";
export default [
    {
        path: "/Login",
        name: "Login",
        component: Login
    },
    {
        path: "/",
        component: Main,
        children: [
            // 菜单列表
            {
                path: "/sys/menu",
                name: "menu",
                component: () =>
                    import("@/views/modules/sys/menu.vue"),
                meta: {
                    title: '菜单列表'
                },
            },
            // 角色列表
            {
                path: "/sys/role",
                name: "role",
                component: () =>
                    import("@/views/modules/sys/role.vue"),
                meta: {
                    title: '角色列表'
                },
            },
            // 员工列表
            {
                path: "/sys/user",
                name: "user",
                component: () =>
                    import("@/views/modules/sys/user.vue"),
                meta: {
                    title: '用户列表'
                },
            },
            {
                path: "/sys/dictionaries",
                name: "dictionaries",
                component: () =>
                    import("@/views/modules/sys/dictionaries.vue"),
                meta: {
                    title: '数据字典'
                },
            },
            {
                path: "/sys/loginUser",
                name: "loginUser",
                component: () =>
                    import("@/views/modules/sys/loginUser.vue"),
                meta: {
                    title: '登录用户'
                },
            },
            // 话术列表
            {
                path: "/sys/talk",
                name: "talk",
                component: () =>
                    import("@/views/modules/sys/talk.vue"),
                meta: {
                    title: '话术列表'
                },
            },

            /* 系统管理 end */
        ]
    }
];