<template>
  <div class="login">
    <div class="login-form">
      <div class="leftContainer">
        <!-- <img
          src="@/assets/images/login/loginLogo.png"
          class="logo"
        > -->
        <img src="@/assets/images/login/logo.png" class="logo" />
        <div class="loginInfo">
          <div class="title">
            {{ loginInfo.title }}
          </div>
          <div class="title2">{{ loginInfo.title2 }}</div>
          <div class="en">{{ loginInfo.en }}</div>
        </div>
      </div>
      <div class="rightContainer">
        <div class="title">{{ loginInfo.right.title }}</div>
        <div class="pading">
          <input
            class="loginUser loginInput"
            type="text"
            :placeholder="loginInfo.right.username"
            v-model="loginForm.username"
          />
          <input
            class="loginPassword loginInput"
            type="password"
            v-model="loginForm.password"
            :placeholder="loginInfo.right.password"
          />
          <div v-if="!PCFlag">
            <sliding-verification-web  />
          </div>
          <div class="slidingVerification" v-else>
            <sliding-verification-pc  />
          </div>
          <div class="loginBtn" @click="signIn">{{ loginInfo.right.btn }}</div>
        </div>
      </div>
    </div>
    <img
      src="@/assets/images/login/loginPictureMatching.png"
      class="loginPictureMatching"
    />
  </div>
</template>

<script>
// 验证码组件
import SlidingVerificationWeb from "./SlidingVerificationWeb.vue";
import SlidingVerificationPc from "./SlidingVerificationPc.vue";
export default {
  components: {
    SlidingVerificationWeb,
    SlidingVerificationPc,
  },
  data() {
    return {
      loginInfo: {},
      loginForm: {},
      PCFlag: false,
      loadingModel: null,
    };
  },
  mounted() {
    localStorage.removeItem("isAdoptVerification");

    let hasTouch = () => {
      let touchObj = {};
      touchObj.isSupportTouch = "ontouchend" in document ? true : false;
      this.PCFlag = touchObj.isSupportTouch ? false : true;
    };
    hasTouch();
    localStorage.setItem("loginUrl", location.hash.replace("#", ""));
    this.loginInfo = {
      title: "后台端",
      title2: "体检信息数据管理系统",
      en: "Data management system of physical examination information in backstage",
      right: {
        title: "登录",
        username: "请输入用户名",
        password: "请输入用密码",
        btn: "登录",
      },
    };
    if (this.$store.state.language == "en") {
      this.loginInfo = {
        title: "Report management system",
        title2: "",
        en: "",
        right: {
          title: "Login",
          username: "Enter one user name",
          password: "Please enter a password",
          btn: "Login",
        },
      };
    }
  },
  methods: {
    /**
     * 登录
     */
    async signIn() {
      let data = this.loginForm;
      if (!data.username) this.$message.warning("请输入用户名");
      else if (!data.password) this.$message.warning("请输入密码");
      else if (localStorage.getItem("isAdoptVerification") == "true") {
        this.loadingModel = this.$loading({
          lock: true,
          text: "正在登陆",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let sysUserLoginFlag = await this.sysUserLogin(data);
        let loginUserFlag = true;
        if (!sysUserLoginFlag) loginUserFlag = await this.loginUser(data, 1);
        if (!loginUserFlag) loginUserFlag = await this.loginUser(data, 2);
        if (!loginUserFlag)
          this.$message({
            message: "用户名或密码错误",
            type: "warning",
          });
        this.loadingModel.close();
      } else {
        if (document.getElementsByClassName("el-message").length === 0)
        if(this.PCFlag) {
          this.$message.warning("请按住滑块，拖动到最右边,完成验证");
        }else {
          this.$message.warning("验证码错误，请重新输入");
        }
      }
    },
    sysUserLogin(data) {
      return new Promise((success, error) => {
        this.$http
          .post(`admin/sys-user/login`, data)
          .then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              if (data) {
                if (data.sysRoleList && data.sysRoleList.length > 0) {
                  data.isAdmin = true
                  localStorage.setItem(
                    "predicthealthUserInfo",
                    JSON.stringify(data)
                  );
                  this.$store.state.loginUserInfo = data;
                  if (
                    data.sysRoleList &&
                    data.sysRoleList.length > 0 &&
                    data.sysRoleList[0].indexUrl
                  )
                    this.$router.replace({
                      path: data.sysRoleList[0].indexUrl,
                    });
                  else
                    this.$router.replace({
                      path: "/user/base",
                    });
                } else {
                  this.$message.error("该账户暂无权限登录，请联系管理员");
                }

                success(true);
              }
            } else {
              success(false);
            }
          })
          .catch((err) => {
            this.$message({
              message: "操作失败，请重试或联系管理员",
              type: "warning",
            });
            success(false);
          });
      });
    },
    loginUser(data, type) {
      return new Promise((success, error) => {
        this.$http
          .post(`user-login/login/${type}`, data)
          .then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              if (data) {
                if (data.roleId) {
                  localStorage.setItem(
                    "predicthealthUserInfo",
                    JSON.stringify(data)
                  );
                  this.$store.state.loginUserInfo = data;
                  this.$router.replace({
                    path: "/testData/reportList",
                  });
                } else {
                  this.$message.error("该账户暂无权限登录，请联系管理员");
                }
                success(true);
              }
            } else {
              success(false);
            }
          })
          .catch((err) => {
            this.$message({
              message: "操作失败，请重试或联系管理员",
              type: "warning",
            });
            success(false);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .login-form {
    transform: translate(-50%, -50%) scale(0.7) !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1900px) {
  .login-form {
    transform: translate(-50%, -50%) scale(0.8) !important;
  }
}
.login {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/login/loginBg.png) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  .loginPictureMatching {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 720px;
    display: flex;
    z-index: 1;
    .leftContainer {
      width: 600px;
      background-image: url("../../assets/images/login/loginLeftImg.png");
      background-size: 600px 720px;
      .logo {
        // width: 280px;
        // margin-left: 60px;
        // margin-top: 40px;
        width: 329px;
        margin-left: 80px;
        margin-top: 80px;
        margin-bottom: 70px;
      }
      .loginInfo {
        padding-left: 80px;
        padding-right: 80px;
      }
      .title {
        font-size: 60px;
        color: #2849aa;
        margin-top: 120px;
      }
      .title2 {
        margin-top: 30px;
        font-size: 40px;
        color: #2849aa;
      }
      .en {
        margin-top: 100px;
        color: #90a7dd;
        font-size: 24px;
        line-height: 34px;
      }
    }
    .rightContainer {
      background-color: #fff;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      width: 600px;
      .pading {
        padding-left: 80px;
        padding-right: 80px;
      }
      .title {
        font-size: 28px;
        color: #2849aa;
        font-weight: 700;
        text-align: center;
        margin-top: 80px;
      }
      input {
        background: none;
        outline: none;
        border: 0px;
        -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
        -webkit-appearance: none;
        border-radius: 0;
        opacity: 1;
      }

      input:disabled {
        opacity: 1;
        -webkit-opacity: 1;
      }
      .loginUser {
        margin-top: 88px !important;
        background-image: url("../../assets/images/login/loginUser.png");
      }
      .loginPassword {
        background-image: url("../../assets/images/login/loginPassword.png");
      }
      .loginInput:focus {
        outline-offset: 0px;
        outline: -webkit-focus-ring-color auto 0px;
        outline: none;
        border-color: #2849aa;
      }
      .loginInput {
        outline: none;
        margin-top: 40px;
        padding-left: 44px;
        display: block;
        width: 100%;
        background-size: 24px;
        background-position: left center;
        background-repeat: no-repeat;
        height: 64px;
        line-height: 64px;
        font-size: 18px;
        border: none;
        border-bottom: 1px solid #f4f4f4;
      }
      ::placeholder {
        color: #999999;
        font-size: 18px;
      }
      .slidingVerification {
        margin-top: 60px;
      }
      .loginBtn {
        margin-top: 70px;
        width: 440px;
        height: 80px;
        background: #2849aa;
        border-radius: 5px;
        font-weight: bold;
        color: #ffffff;
        line-height: 80px;
        font-size: 28px;
        text-align: center;
      }
    }
  }
}

// 添加平板的媒体查询
@media screen and (min-width: 768px) and (max-width: 999px) {
  .login {
    .login-form {
      width: 90%;
      height: auto;
      flex-direction: column;
      transform: translate(-50%, -50%) scale(0.9) !important;

      .leftContainer, .rightContainer {
        width: 100%;
      }

      .leftContainer {
        padding: 40px 0;
        background-size: cover;
        
        .logo {
          width: 200px;
          margin: 20px auto;
          display: block;
        }

        .loginInfo {
          padding: 0 40px;
          text-align: center;

          .title {
            font-size: 40px;
            margin-top: 40px;
          }

          .title2 {
            font-size: 30px;
          }

          .en {
            font-size: 20px;
            margin-top: 40px;
          }
        }
      }

      .rightContainer {
        padding: 40px 0;

        .pading {
          padding: 0 40px;
        }

        .loginBtn {
          width: 100%;
          height: 60px;
          line-height: 60px;
          font-size: 24px;
        }
      }
    }
  }
}

// 添加手机的媒体查询
@media screen and (max-width: 767px) {
  .login {
    .loginPictureMatching {
      display: none; // 在手机上隐藏底部装饰图
    }

    .login-form {
      width: 100%;
      height: 100%;
      flex-direction: column;
      transform: none !important;
      position: relative;
      left: 0;
      top: 0;

      .leftContainer, .rightContainer {
        width: 100%;
      }

      .leftContainer {
        padding: 20px 0;
        
        .logo {
          width: 160px;
          margin: 10px auto;
          display: block;
        }

        .loginInfo {
          padding: 0 20px;
          text-align: center;

          .title {
            font-size: 32px;
            margin-top: 20px;
          }

          .title2 {
            font-size: 24px;
            margin-top: 15px;
          }

          .en {
            font-size: 16px;
            margin-top: 20px;
            line-height: 24px;
          }
        }
      }

      .rightContainer {
        padding: 20px 0;

        .title {
          margin-top: 20px;
          font-size: 24px;
        }

        .pading {
          padding: 0 20px;
        }

        .loginUser {
          margin-top: 40px !important;
        }

        .loginInput {
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          margin-top: 20px;
        }

        .slidingVerification {
          margin-top: 30px;
        }

        .loginBtn {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 20px;
          margin-top: 40px;
        }
      }
    }
  }
}
</style
>>
