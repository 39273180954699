import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import htmltopdf from "./utils/htmltopdf";
Vue.use(htmltopdf)
/* 引入 ElementUI*/
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from 'element-ui/lib/locale/lang/en'
if (location.host.indexOf("admin.predicthealth.cn") != -1) {
    Vue.use(ElementUI)
} else Vue.use(ElementUI, { locale })

// echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
/* 引入一些需要多处使用的方法 */
import { isAuth, processingDataQuery, getUserId, getUserRoleName, getQueryId, getName, getProvince, getCity, getArea } from '@/utils'
Vue.prototype.isAuth = isAuth     // 按钮权限方法
Vue.prototype.processingDataQuery = processingDataQuery      // 查询条件数据处理
Vue.prototype.getUserId = getUserId      // 获取当前用户id
Vue.prototype.getUserRoleName = getUserRoleName      // 获取当前角色名称
Vue.prototype.getQueryId = getQueryId      // 获取当前查询用户id
Vue.prototype.getName = getName      // 获取当前名称
Vue.prototype.getProvince = getProvince      // 获取所有的省份
Vue.prototype.getCity = getCity      // 获取所有的城市
Vue.prototype.getArea = getArea      // 获取所有的区县
// 引入axios 并配置axios和qs
import axios from "axios";
Vue.prototype.$http = axios;
import qs from "qs";
Vue.prototype.$qs = qs;
/* 配置接口基础api地址 */
if (location.port || location.host.indexOf("test.") != -1) {
    // axios.defaults.baseURL = "http://121.41.24.250:8119/"
    // axios.defaults.baseURL = "https://47070f2j95.oicp.vip/"
    axios.defaults.baseURL = "https://api.predicthealth.cn/"
} else
    axios.defaults.baseURL = "https://api.predicthealth.cn/"
Vue.prototype.$guolv = function (data) {
    let allConditions = ['allEq', 'eq', 'ge', 'in', 'isNotNull', 'isNull', 'le', 'like', 'ne', 'orderBy', 'select', 'set']
    let object = {}
    for (const key in data) {
        if (allConditions.includes(key)) {
            object[key] = []
            data[key].forEach(item => {
                if (item[2]) {
                    object[key].push({
                        "fieldDataType": item[0],
                        "fieldName": item[1],
                        "fieldValue": item[2]
                    })
                }
            });
        } else object[key] = data[key]
    }
    return object;
}
// 请求拦截器
axios.interceptors.request.use(
    config => {

        if (location.host.indexOf("admin.predicthealth.cn") != -1) {
            config.headers['Accept-Language'] = 'zh-CN'
        } else
            config.headers['Accept-Language'] = 'en-US'
        if (config.qs == "yes")
            config.data = qs.stringify(config.data);
        return config;
    },
    err => {
        // 对请求错误做些什么
        return Promise.reject(err);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    res => {
        return res;
    },
    err => {
        return err.response;
    }
);
/* 全局方法 */
/*过滤器年月日时分秒（默认日期为空字符串）*/
Vue.filter("dateFormat1", function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let year = value.getFullYear();
        let month = value.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        let day = value.getDate();
        if (day < 10) {
            day = "0" + day
        }
        let hour = value.getHours();
        if (hour < 10) {
            hour = "0" + hour
        }
        let minutes = value.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        let seconds = value.getSeconds();
        if (seconds < 10) {
            seconds = "0" + seconds
        }
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
    }
})
Vue.filter("dateFormat2", function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let year = value.getFullYear();
        let month = value.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        let day = value.getDate();
        if (day < 10) {
            day = "0" + day
        }
        return year + '-' + month + '-' + day;
    }
})
Vue.filter("dateFormat3", function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let year = value.getFullYear();
        let month = value.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        let day = value.getDate();
        if (day < 10) {
            day = "0" + day
        }
        return year + '年' + month + '月' + day + '日';
    }
})
Vue.filter("dateFormat4", function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let year = value.getFullYear();
        let month = value.getMonth() + 1
        let monthTxt = ''
        // if (month < 10) {
        //     month = "0" + month
        // }
        switch (month) {
            case 1:
                monthTxt = 'January'
                break
            case 2:
                monthTxt = 'February'
                break
            case 3:
                monthTxt = 'March'
                break
            case 4:
                monthTxt = 'April'
                break
            case 5:
                monthTxt = 'May'
                break
            case 6:
                monthTxt = 'July'
                break
            case 7:
                monthTxt = 'July'
                break
            case 8:
                monthTxt = 'August'
                break
            case 9:
                monthTxt = 'September'
                break
            case 10:
                monthTxt = 'October'
                break
            case 11:
                monthTxt = 'November'
                break
            case 12:
                monthTxt = 'December'
                break

        }
        let day = value.getDate();
        if (day < 10) {
            day = "0" + day
        }
        return `${monthTxt} ${day}, ${year}`;
    }
})
Vue.filter("dateFormatWeek", function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let month = value.getMonth() + 1
        if (month < 10)
            month = "0" + month
        let day = value.getDate();
        if (day < 10)
            day = "0" + day
        let week = value.getDay()
        switch (week) {
            case 1:
                week = '周一';
                break;
            case 2:
                week = '周二';
                break;
            case 3:
                week = '周三';
                break;
            case 4:
                week = '周四';
                break;
            case 5:
                week = '周五';
                break;
            case 6:
                week = '周六';
                break;
            case 0:
                week = '周日';
                break;
        }
        return month + '.' + day + '（' + week + '）';
    }
})

/* 同过滤器 dateFormat1*/
Vue.prototype.dateFormat1 = function (time) {
    time = Vue.prototype.$guolvTime(time)
    let data = new Date(time)
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    let h = data.getHours()
    let M = data.getMinutes()
    let s = data.getSeconds()
    if (m < 10)
        m = '0' + m
    if (d < 10)
        d = '0' + d
    if (h < 10)
        h = '0' + h
    if (M < 10)
        M = '0' + M
    if (s < 10)
        s = '0' + s
    return `${y}-${m}-${d} ${h}:${M}:${s}`
}
/* 同过滤器 dateFormat2*/
Vue.prototype.dateFormat2 = function (time) {
    time = Vue.prototype.$guolvTime(time)
    let data = new Date(time)
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    if (m < 10)
        m = '0' + m
    if (d < 10)
        d = '0' + d
    return `${y}-${m}-${d}`
}
Vue.prototype.dateFormat4 = function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let year = value.getFullYear();
        let month = value.getMonth() + 1
        let monthTxt = ''
        switch (month) {
            case 1:
                monthTxt = 'January'
                break
            case 2:
                monthTxt = 'February'
                break
            case 3:
                monthTxt = 'March'
                break
            case 4:
                monthTxt = 'April'
                break
            case 5:
                monthTxt = 'May'
                break
            case 6:
                monthTxt = 'July'
                break
            case 7:
                monthTxt = 'July'
                break
            case 8:
                monthTxt = 'August'
                break
            case 9:
                monthTxt = 'September'
                break
            case 10:
                monthTxt = 'October'
                break
            case 11:
                monthTxt = 'November'
                break
            case 12:
                monthTxt = 'December'
                break

        }
        let day = value.getDate();
        if (day < 10) {
            day = "0" + day
        }
        return `${monthTxt} ${day}, ${year}`;
    }
}
Vue.prototype.dateFormatWeek = function (time) {
    if (!time) {
        return ""
    } else {
        time = Vue.prototype.$guolvTime(time)
        let value = new Date(time);
        let month = value.getMonth() + 1
        if (month < 10)
            month = "0" + month
        let day = value.getDate();
        if (day < 10)
            day = "0" + day
        let week = value.getDay() + 1
        switch (week) {
            case 1:
                week = '周一';
                break;
            case 2:
                week = '周二';
                break;
            case 3:
                week = '周三';
                break;
            case 4:
                week = '周四';
                break;
            case 5:
                week = '周五';
                break;
            case 6:
                week = '周六';
                break;
            case 0:
                week = '周日';
                break;
        }
        return month + '.' + day + '（' + week + '）';
    }
}
/*退出登录*/
Vue.prototype.$logout = function () {
    this.$router.replace(localStorage.getItem("loginUrl") || "/Login");
    localStorage.clear()
    sessionStorage.clear()
}
/* 当天时间时分秒*/
Vue.prototype.getTodayTime1 = function () {
    let data = new Date()
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    let h = data.getHours()
    let M = data.getMinutes()
    let s = data.getSeconds()
    if (m < 10) {
        m = '0' + m
    }
    if (d < 10) {
        d = '0' + d
    }
    if (h < 10) {
        h = '0' + h
    }
    if (M < 10) {
        M = '0' + M
    }
    if (s < 10) {
        s = '0' + s
    }

    return `${y}-${m}-${d} ${h}:${M}:${s}`

}

/* 当天时间年月日*/
Vue.prototype.getTodayTime2 = function () {
    let data = new Date()
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    if (m < 10)
        m = '0' + m
    if (d < 10)
        d = '0' + d
    return `${y}-${m}-${d}`

}

/*获取当月的第一天*/
Vue.prototype.getCurrentMonthFirst = function () {
    let d = new Date(new Date().setDate(1))
    let year = d.getFullYear()
    let month = d.getMonth() + 1 // 记得当前月是要+1的
    if (month < 10) {
        month = '0' + month
    }
    let dt = d.getDate()
    if (dt < 10) {
        dt = '0' + dt
    }
    let monthFirstDay = year + '-' + month + '-' + dt
    return monthFirstDay
}


/* 时间戳转换*/
Vue.prototype.convertTimestamps = function (time) {
    if (time == null || time == '')
        return ""
    time = Vue.prototype.$guolvTime(time)
    let data = new Date(time)
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    if (m < 10)
        m = '0' + m
    if (d < 10)
        d = '0' + d
    return `${y}-${m}-${d}`
}
Vue.prototype.$guolvTime = function (time) {
    // time = Date.parse(time)
    // if (typeof time == 'string' && time.indexOf('-') > 0 && time.indexOf('.')) {
    //     // time = time.split('.')[0].replace('T', ' ').replace(/-/g, '/')
    //     console.log(time)
    //     // time = Date.parse(time) + 8 * 60 * 60 * 1000;
    // }
    return time
}
/**
 * 获取所有企业
 */
Vue.prototype.$getEnterpriseAllList = function () {
    this.$http.get(`channel/enterprise/list`).then((res) => {
        if (res.data.code == 200) {
            let list = res.data.data;
            let newList = []
            list.forEach(item => {
                newList.push({
                    id: item.id,
                    name: item.name
                })
            });
            this.$store.state.enterpriseAllList = newList;
        }
    });
}
/**
 * 获取所有医院
 */
Vue.prototype.$getHospitalAllList = function () {
    this.$http.get(`channel/hospital/list`).then((res) => {
        if (res.data.code == 200) {
            let list = res.data.data;
            let newList = []
            list.forEach(item => {
                newList.push({
                    id: item.id,
                    name: item.name
                })
            });
            this.$store.state.hospitalAllList = res.data.data;
        }
    });
}
Vue.prototype.$getToDay = function () {
    let value = new Date();
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = value.getDate();
    if (day < 10) day = "0" + day;
    let week = value.getDay();
    switch (week) {
        case 1:
            week = "一";
            break;
        case 2:
            week = "二";
            break;
        case 3:
            week = "三";
            break;
        case 4:
            week = "四";
            break;
        case 5:
            week = "五";
            break;
        case 6:
            week = "六";
            break;
        case 0:
            week = "天";
            break;
    }
    this.toMonth = month;
    return year + "-" + month + "-" + day + " 星期" + week;
}
/* 统一的上传图片前的处理 */
Vue.prototype.$beforeUploadImage = function (file) {
    if (file.type && file.type.indexOf("image") != -1) {
        this.$store.state.beforeUploadLoading = this.$loading({
            lock: true,
            text: "正在上传，请稍等......",
            spinner: "el-icon-loading",
        });
    } else {
        this.$message({
            message: "请上传图片格式的文件",
            type: "warning",
        });
        return false;
    }
}
Vue.prototype.$beforeUploadFile = function (file) {
    this.$store.state.beforeUploadLoading = this.$loading({
        lock: true,
        text: "正在上传，请稍等......",
        spinner: "el-icon-loading",
    });

}
/* 统一的上传图片错误处理 */
Vue.prototype.$uploadError = function () {
    this.$store.state.beforeUploadLoading.close();
    this.$message({
        message: "操作失败，请重试或联系管理员",
        type: "warning",
    });
}
Vue.config.productionTip = false;
import 'default-passive-events'
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");