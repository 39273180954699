import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        closureFlag: true,
        globalLoading: false, //全局加载
        loginUserInfo: JSON.parse(localStorage.getItem("currentLoginUser")) || {}, //用户登录信息
        isCollapse: false, //是否折叠左侧栏
        componentCacheList: ["OrderList"], //缓存页面列表
        chanelList: [], //渠道列表
        uploadImgUrl: "",//上传图片
        baseUrl: "http://121.41.24.250:8119/api/",
        menuList: [], //菜单列表数据
        /* 渠道有未读消息显示红点 */
        enterprise_1: 0,//企业一审
        enterprise_2: 0,//企业二审
        hospital: 0,//医院审核
        cooperation: 0,//合作申请
        enterpriseAllList: [],//全局通用所有企业列表
        hospitalAllList: [],//全局通用的所有医院列表
        beforeUploadLoading: null,//上传文件前的loading动画

        language: 'zh'
    },
    mutations: {
    },
    actions: {},
    modules: {}
});