<template>
  <div class="sidebar">
    <div class="logoBox">
      <img src="@/assets/images/login/logo.png" class="logo" />
      <div class="logoTxt">
        {{
          $store.state.language == "en"
            ? "Report management system"
            : "后台管理系统"
        }}
      </div>
    </div>
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="$store.state.isCollapse"
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#298ae3"
      unique-opened
      router
    >
      <template v-for="item in menuList">
        <template v-if="item.list.length > 0">
          <el-submenu :index="item.menuId + ''" :key="item.menuId">
            <template slot="title">
              <span slot="title">{{
                $store.state.language == "en" ? item.enName : item.name
              }}</span>
            </template>
            <template v-for="subItem in item.list">
              <a
                :href="subItem.url"
                v-if="subItem.url.indexOf('http') != -1"
                target="_blank"
                >{{ subItem.name }}</a
              >
              <el-menu-item
                v-else
                :index="subItem.url"
                :key="subItem.menuId"
                :class="{ 'is-active': $route.path.indexOf(subItem.url) != -1 }"
              >
                <img class="icon" v-if="subItem.icon" :src="subItem.icon" />
                <span slot="title">{{
                  $store.state.language == "en" ? subItem.enName : subItem.name
                }}</span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-submenu :index="item.url" :key="item.menuId">
            <template slot="title">
              <span slot="title">{{
                $store.state.language == "en" ? item.enName : item.name
              }}</span>
            </template>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [],
      activeMenu: "",
      typeName: "",
    };
  },
  computed: {
    onRoutes() {
      return this.activeMenu || this.$route.path;
    },
  },
  created() {
    //加载菜单列表
    if (
      this.$store.state.loginUserInfo.userId ||
      this.$store.state.loginUserInfo.roleId
    ) {
      this.getMenu();
    } else {
      this.$router.replace({
        name: "Login",
      });
    }
  },
  methods: {
    /**
     * 获取菜单
     */
    getMenu() {
      this.$store.state.globalLoading = true;
      let loginUserInfo = this.$store.state.loginUserInfo;
      let url = `admin/sys-menu/nav?userId=${loginUserInfo.userId}`;
      if (loginUserInfo.roleId) {
        //不存在用户id，用角色id查询权限
        url = `admin/sys-menu/navRoleId?roleId=${loginUserInfo.roleId}`;
      }
      this.$http
        .get(url)
        .then((res) => {
          if (res.status == 500) {
            this.$logout();
            return;
          }
          if (res.data.code == 200) {
            this.$store.state.globalLoading = false;
            if (res.data.data) {
              let list = res.data.data;
              this.menuList = list;
              let list2 = [];
              let list3 = [];
              this.getRoleMenuList(list, list2, list3);
              localStorage.setItem("urlJurisdiction", JSON.stringify(list3));
              localStorage.setItem("permissions", JSON.stringify(list2));
            }
          }
        })
        .catch((err) => {
          this.$store.state.globalLoading = false;
          this.$logout();
        });
    },

    /**
     * 递归 获取授权菜单
     */
    getRoleMenuList(data, list, list3) {
      data.forEach((item) => {
        if (item.list.length > 0) {
          this.getRoleMenuList(item.list, list, list3);
        } else if (item.perms) list.push(item.perms);
        if (item.url) list3.push(item.url);
        if (this.$route.path.indexOf(item.url) != -1)
          this.activeMenu = item.url;
      });
    },
  },
  watch: {},
};
</script>

<style  lang="less">
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow-y: scroll;
  overflow-y: overlay;
  height: 100%;
  width: 200px;
  float: left;
  background-color: #0f1325;
  .logoBox {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .logo {
    // width: 80px;
    width: 180px;
    display: block;
    margin: 10px auto 30px;
  }
  .logoTxt {
    color: #fff;
    text-align: center;
    font-size: 17px;
    margin-top: 20px;
    font-weight: 700;
  }
  .sidebar-el-menu {
    background-color: #0f1325;
    border: none !important;
    font-weight: 700;
    .el-submenu {
      background-color: #0f1325 !important;
      text-align: left;
      a {
        padding: 0 45px;
        display: block;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #c2c7db;
        background-color: #1c2134;
      }
      .el-submenu__title:hover {
        background-color: #2d3555 !important;
      }
      .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
        background-color: #0f1325 !important;
        span {
          font-size: 14px !important;
        }
        .el-submenu__icon-arrow {
          font-size: 18px !important;
          color: #fff !important;
        }
      }
      .el-menu-item:hover,
      a:hover {
        background-color: #2d3555 !important;
      }
      .el-menu-item {
        color: #c2c7db !important;
        background-color: #1c2134 !important;
        font-size: 14px !important;
        height: 50px;
        line-height: 50px;
      }
      .el-menu-item.is-active {
        background-color: #409eff !important;
      }
      img.icon {
        width: 25px;
        margin-right: 10px;
        opacity: 0.6;
      }
      .redDot {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ff3737;
      }
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 10px;
  // background-color: #fff;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
</style>
