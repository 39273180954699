
import Main from "../views/BasePage/Main.vue";
export default [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "/testData/reportList",
        name: "reportList",
        component: () =>
          import("../views/modules/testData/reportList.vue"), meta: {
            title: location.host.indexOf("admin.predicthealthen.com") !== -1 ? 'Customer Report List' : '报告列表'
          },
      },
      {
        path: "/testData/EPISList",
        name: "EPISList",
        component: () =>
          import("../views/modules/testData/EPISList.vue"), meta: {
            title: 'EPISList'
          },
      },
      {
        path: "/testData/EPISDetails",
        name: "EPISDetails",
        component: () =>
          import("../views/modules/testData/EPISDetails.vue"), meta: {
            title: 'EPISDetails'
          },
      },
    ]
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import("../components/404.vue")
  },
]