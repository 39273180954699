import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import commonRouter from "./commonRouter.js";
import model1 from "./model1.js";
import model2 from "./model2.js";

const router = new VueRouter({
    routes: [
        ...model1,
        ...model2,
        ...commonRouter
    ]
});

export default router;